import React, { useState, useEffect, useContext, useMemo } from 'react';
import {
  EnrollmentApiRequestData,
  DemoApiRequestData,
} from '../../types/memberOnboardingTypes';
import {
  getAccessToken,
  getUserMatch,
  postUsersSimulateIdentity,
} from '../../actions/EnrollmentActions';
import { runHighlineLinkDC } from '../../helpers/HighlineLinkHelpers';
import {
  addMonths,
  formatDollarsToCents,
} from '../../helpers/CalculationsHelpers';
import './MemberOnboardingDPA.css';

import { ReactComponent as Logo } from '../../assets/images/dpa/logo.svg';
import { ReactComponent as PiggyBank } from '../../assets/images/dpa/piggy-bank.svg';
import { ConfigContext } from '../../ConfigContext';

interface Props {
  partnerId?: string;
}

// const ssn: string = '999999999';

export const MemberOnboardingDPA: React.FC<Props> = () => {
  const [step, setStep] = useState(1);
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error('Component must be used within a ConfigContext.Provider');
  }

  const memoizedConfig = useMemo(() => context.config, [context.config]);

  useEffect(() => {
    document.title = 'Highline - Direct Payroll Access';
    (window as any).highline_link_success = highlineLinkSuccess;
    (window as any).highline_link_error = highlineLinkError;
    (window as any).highline_link_close = highlineLinkClose;
  }, []);

  const [isShowCongratulations, setIsShowCongratulations] = useState(false);

  const highlineLinkSuccess = () => {
    console.log('highlineLinkSuccess');
    setStep(2);
  };

  const highlineLinkError = () => {
    console.log('highlineLinkError');
  };

  const highlineLinkClose = () => {
    console.log('highlineLinkClose');
  };

  const runHighlineLinkAction = () => {
    const montlyPaymentAmountCents = formatDollarsToCents('4');
    const payment_amount_cents = Math.floor(montlyPaymentAmountCents);

    const productId = memoizedConfig.product_flow.flow_one_product_id;

    let enrollmentApiRequestData: EnrollmentApiRequestData = {
      product_id: productId,
      payment_amount: payment_amount_cents,
      first_payment_date: Math.floor(addMonths(new Date(), 1).getTime() / 1000),
      payment_frequency: 'monthly',
    };

    let demoApiRequestData: DemoApiRequestData = {
      feature: 'payments',
    };

    getAccessToken(
      (demoApiResponse: any) => {
        window.accessToken = demoApiResponse.access_token;

        // Call postUsersSimulateIdentity before getUserMatch
        postUsersSimulateIdentity(
          (simulateResponse) => {
            // Extract the ssn from the simulateResponse
            const ssn = simulateResponse.ssn;

            getUserMatch(
              (response) => {
                runHighlineLinkDC(
                  window.accessToken,
                  demoApiRequestData.feature,
                  response.applicant_token
                );
              },
              (error) => {
                console.log(error);
              },
              memoizedConfig,
              ssn
            );
          },
          (simulateError) => {
            console.log(simulateError);
          },
          memoizedConfig,
          () => {}, // beforeAction
          () => {} // afterAction
        );
      },
      (errorMessage: any) => {
        alert(errorMessage);
      },
      memoizedConfig,
      productId,
      demoApiRequestData,
      () => {},
      () => {}
    );
  };

  return (
    <div className="dpa">
      <div
        dangerouslySetInnerHTML={{
          __html: "<highline-link id='highline-link'/>",
        }}
      />

      <div className="dpa__container">
        <div className="dpa__header">
          <div className="dpa__logo">
            <div className="dpa__logo__image">
              <Logo />
            </div>
            <div className="dpa__logo__caption">Roadrunner Bank</div>
          </div>
        </div>

        <div className="dpa__body">
          {step === 1 && (
            <>
              <p>Congratulations! You’re approved for </p>
              <div className="dpa__body__amount">$10,000</div>
              <div className="dpa__precard__text">
                Please select a loan offer
              </div>
              <div className="dpa__cards__container">
                <div className="dpa__card">
                  <div className="dpa__card__header">Pay from Bank Account</div>

                  <div className="dpa__card__body">
                    <div className="dpa__card__amount">$351.08</div>
                    <div className="dpa__card__amount__title">
                      Monthly Payment
                    </div>

                    <div className="dpa__card__spacer" />

                    <div className="dpa__card__details">
                      <div className="dpa__card__details__block">
                        <div className="dpa__card__details__apr">15.9%</div>
                        <div className="dpa__card__details__term">APR</div>
                      </div>

                      <div className="dpa__card__details__block">
                        <div className="dpa__card__details__apr">36 mo.</div>
                        <div className="dpa__card__details__term">Term</div>
                      </div>
                    </div>
                  </div>

                  <div className="dpa__card__footer">
                    <div className="dpa__card__button">Select</div>
                  </div>
                </div>

                <div className="dpa__card dpa__card--highlight">
                  <div className="dpa__card__header">Pay from Paycheck</div>

                  <div className="dpa__card__body">
                    <div className="dpa__card__amount">$341.29</div>
                    <div className="dpa__card__amount__title">
                      Monthly Payment
                    </div>
                    <div className="dpa__card__amount__descr">
                      Total Savings = $352.30
                    </div>

                    <div className="dpa__card__spacer" />

                    <div className="dpa__card__details">
                      <div className="dpa__card__details__block">
                        <div className="dpa__card__details__apr">13.9%</div>
                        <div className="dpa__card__details__term">APR</div>
                      </div>

                      <div className="dpa__card__details__block">
                        <div className="dpa__card__details__apr">36 mo.</div>
                        <div className="dpa__card__details__term">Term</div>
                      </div>
                    </div>
                  </div>

                  <div className="dpa__card__footer">
                    <div
                      className="dpa__card__button"
                      onClick={runHighlineLinkAction}
                    >
                      Select
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {step === 2 && (
            <div className="dpa__body--narrow">
              <div className="dpa__piggybank">
                <PiggyBank />
              </div>

              <p>
                Success! We've prepared your funds and want to confirm your
                payment agreement with us via Highline.
              </p>

              <div className="dpa__outcome">
                <div className="dpa__outcome__row">
                  <span>Monthly payment</span>
                  <span>$341.29</span>
                </div>

                <div className="dpa__outcome__row">
                  <span>Deduction per paycheck</span>
                  <span>$170.65</span>
                </div>
              </div>

              <div
                className="dpa__body__button"
                onClick={() => {
                  setStep(3);
                }}
              >
                Confirm
              </div>
            </div>
          )}

          {step === 3 && (
            <>
              <div className="dpa__body--narrow">
                <div className="dpa__piggybank">
                  <PiggyBank />
                </div>

                <p>That’s all, folks! Your money is on the way!</p>

                <div className="dpa__body__result">
                  Your funds should arrive within 2 business days. <br />A
                  confirmation will be delivered to your email shortly!
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
